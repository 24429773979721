// FONT

$font: "Open Sans", sans-serif;

// COLOR

// $background-color: #addbea;
// $color-background-primary: #f5f5f5;
// $color-1: #023859;
// $color-2: lighten(#73b9d4, $amount: 10);
// $color-3: #3999c4;
// $color-error: #d9534f;

body {
  margin: auto;
  // padding: 20px;
  // padding-top: 80px;
  //   background-color: red;
  max-width: 100%;

  font-family: $font;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
  outline: none;
  border: none;
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 100vw;
  margin-block: 0.2em;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(#c4c5c9, black);
  border: 0.15em solid white;
  border-radius: 100vw;

  &:hover {
    background: linear-gradient(black, #c4c5c9);
  }
}

// input,
// textarea {
//   padding: 14px 20px;
//   resize: none;
//   border-radius: 10px;
//   width: 270px;
//   height: 40px;
//   font-family: $font;
//   background: white;
//   font-size: 1.2em;
// }
// textarea {
//   &:focus {
//     box-shadow: 0 0 4px rgba(0, 22, 44, 0.1);
//   }
// }

a {
  text-decoration: none;
  color: black;
}

// button,
// input[type="submit"] {
//   background: $color-2;
//   color: $color-1;
//   font-weight: bold;

//   cursor: pointer;
//   transition: 0.2s;
//   margin: 0;
//   padding: 10px 20px;
//   cursor: pointer;
//   font-size: 1.1rem;
//   border-radius: 10px;

//   &:hover {
//     background: $color-1;
//     color: $color-2;
//   }
// }
