.wall-event,
.wall-video,
.wall-tourDate {
  max-width: 1200px;
  margin: auto;
  margin-top: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media screen and(max-width: 630px) {
    margin-top: 38px;
  }

  @media screen and(max-width: 500px) {
    margin-top: 20px;
  }

  .card-event-container {
    width: 582px;
    margin-bottom: 18px;
    margin-right: 9px;
    margin-left: 9px;
    box-shadow: 0 4px 10px 0 rgba($color: black, $alpha: 0.5);

    // @media screen and(max-width: 1199px) {
    //   margin-left: 18px;
    // }
    @media screen and(max-width: 1200px) {
      width: 550px;
    }
    @media screen and(max-width: 1136px) {
      width: 450px;
    }
    @media screen and(max-width: 500px) {
      margin-left: 28px;
      margin-right: 28px;
      margin-bottom: 28px;
    }

    .img-container {
      width: 100%;
      padding: 8px;
      img {
        width: 100%;
      }
    }

    .event-main {
      h3 {
        padding: 0 8px;
        letter-spacing: 0px;
        font-size: 0.9em;
      }

      .second {
        padding-bottom: 8px;
        padding-top: 0px;
      }
      .last {
        padding-bottom: 8px;
      }
    }
  }

  iframe {
    margin: 0 9px 18px 9px;
    box-shadow: 0 4px 10px 0 rgba($color: black, $alpha: 0.5);

    @media screen and(max-width: 750px) {
      width: 100%;
    }
  }
  .img-tourdate {
    width: 100%;

    img {
      width: 100%;
    }
  }
  .container-widget {
    display: flex;
    width: 100%;
    margin-top: -130px;
    background-color: #fff;
    border-radius: 4px;

    @media screen and(max-width: 630px) {
      margin-top: -70px;
    }

    div {
      width: 100%;
      height: 100%;

      iframe {
        border-radius: 4px;

        font-family: $font;
        margin: 0px;
      }
    }
  }
}

.img-tourdate {
  width: 100%;
  height: 50vh;
  margin-top: 100px;
  @media screen and(max-width: 630px) {
    margin-top: 38px;
  }

  @media screen and(max-width: 500px) {
    margin-top: 20px;
  }
  img {
    width: 100%;
    height: 50vh;
    object-fit: cover;
  }
}
