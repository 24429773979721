.music {
  // animation: translate 0.6s forwards ease-in-out;
  // animation-delay: 4s;
}

.title-groupe {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 190px;
  @media screen and(max-width: 630px) {
    margin-top: 120px;
  }
  @media screen and(max-width: 500px) {
    margin-top: 20px;
  }
  @media screen and(max-width: 320px) {
    margin-top: 20px;
  }
}
.title-page {
  margin-right: 11%;
  font-size: 5em;
  letter-spacing: 30px;
  font-weight: bold;
  border-right: 3px solid black;
  width: fit-content;

  @media screen and(max-width: 630px) {
    font-size: 1.5em;
    letter-spacing: 20px;
  }
}

.contener-loader {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  background-color: black;
  z-index: 900000;
  display: flex;
  justify-content: center;
  align-items: center;

  .loading-logo {
    width: 50vw;
    animation: loading-effect 1.5s linear infinite;
  }
}

.hidden {
  opacity: 0;
  background: transparent;
  transition: all 0.6s ease-out;
  transform: translateY(-100%) translateX(-100%) scale(0.15);

  @media screen and(max-width: 900px) {
    transform: translateY(-100%) scale(0);
  }
}

@keyframes loading-effect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}
