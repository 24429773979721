.nav-case {
  display: flex;
  padding-top: 100vh;
  position: relative;

  @media screen and(max-width: 750px) {
    flex-direction: column;
  }

  .goDown {
    position: absolute;
    width: 60px;
    height: 70px;
    bottom: 325px;
    left: 50%;
    transform: translate(-30px, -2.5%);

    @media screen and(max-width: 750px) {
      bottom: 1225px;
    }
    @media screen and(max-width: 500px) {
      bottom: 1400px;
    }

    i {
      position: absolute;

      font-size: 2.5em;

      animation-name: toDown;
      animation-duration: 1.2s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    @keyframes toDown {
      0% {
        transform: translateY(-20px);
        opacity: 0;
      }
      50% {
        transform: translateY(0px);
        opacity: 1;
      }
      75% {
        transform: translateY(20px);
        opacity: 0;
      }
      100% {
        transform: translateY(-20px);
        opacity: 0;
      }
    }

    .fI {
      color: rgba($color: white, $alpha: 1);
      top: 60%;
      left: 13.5px;
    }

    .sI {
      color: rgba($color: white, $alpha: 0.5);
      top: 30%;
      left: 13.5px;
    }
  }
  a {
    position: relative;
    width: 25%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and(max-width: 750px) {
      width: 100%;
    }
    &::after {
      content: "";
      position: absolute;
      background-color: white;
      bottom: 0;
      left: 0;
      right: 0;
      top: 100%;
      opacity: 0.4;
      transition: 0.5s;
    }
    &:hover::after {
      top: 0;
    }
    h3 {
      font-size: 28px;
      color: white;
      text-shadow: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em black;
    }
  }

  .nav-music {
    background-image: url(../../image/nav-case/MUSIC.jpg);
    background-size: cover;
    background-repeat: no-repeat;
  }
  .nav-artist {
    background-image: url(../../image/nav-case/ARTISTS.JPG);
    background-size: cover;
    background-repeat: no-repeat;
  }
  .nav-event {
    background-image: url(../../image/nav-case/EVENTS.jpg);
    background-size: cover;
    background-repeat: no-repeat;
  }
  .nav-shop {
    background-image: url(../../image/nav-case/SHOP.JPG);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .nav-musicT {
    background-image: url(../../image/nav-caseTeho/Music.jpg);
    background-size: cover;
    background-repeat: no-repeat;
  }
  .nav-video {
    background-image: url(../../image/nav-caseTeho/Video.jpg);
    background-size: cover;
    background-repeat: no-repeat;
  }
  .nav-tour-dates {
    background-image: url(../../image/nav-caseTeho/TourDates.jpg);
    background-size: cover;
    background-repeat: no-repeat;
  }
  .nav-shopT {
    background-image: url(../../image/nav-caseTeho/SHOP.jpg);
    background-size: cover;
    background-repeat: no-repeat;
  }
}
