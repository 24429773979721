#about-us,
#about {
  background: white;

  .bg-about-us {
    margin: auto;
    max-width: 1200px;
    padding-top: 100px;
    z-index: 2000;
    background: white;

    @media screen and(max-width: 750px) {
      width: 95%;
      padding-top: 64px;
    }

    .video-info {
      display: flex;
      padding: 8px;
      justify-content: space-between;

      @media screen and(max-width: 750px) {
        flex-direction: column;
        align-items: center;
      }

      iframe {
        width: 48%;
        min-height: 330px;
        box-shadow: 0 0 3px rgba(51, 51, 51, 0.5);

        @media screen and(max-width: 750px) {
          width: 100%;
        }
      }

      .info {
        margin-left: 32px;
        width: 48%;
        @media screen and(max-width: 750px) {
          width: 100%;
          margin-left: 0;
          margin-top: 16px;
        }
        p {
          tab-size: 4px;
          font-family: $font;
          padding-top: 8px;
          text-align: justify;
          letter-spacing: 0.1px;
          font-weight: 400;
        }

        .staff-team {
          padding-top: 16px;
          display: flex;

          .sound-engineer {
            padding-left: 8px;
            width: 50%;
            border-left: 2px solid black;
            // a {
            //   padding-left: 8px;
            // }
          }

          .graphic-design {
            padding-left: 8px;
            width: 50%;
            border-left: 2px solid black;

            // a {
            //   padding-left: 8px;
            // }
          }
        }
      }

      .info-Teho {
        width: 100%;
        margin-left: 0;
      }
    }

    .for-contact {
      display: flex;
      padding: 8px;
      justify-content: space-between;
      margin-top: 32px;

      @media screen and(max-width: 750px) {
        flex-direction: column;
      }

      .demo {
        padding-left: 8px;
        width: 48%;
        border-left: 2px solid black;
        @media screen and(max-width: 750px) {
          width: 100%;
        }

        p {
          text-align: justify;
          letter-spacing: 0.3px;
          font-weight: 400;

          // @media screen and(max-width: 750px) {
          //   text-align: start;
          // }
        }
      }

      .booking {
        padding-left: 8px;
        width: 48%;
        margin-left: 32px;
        border-left: 2px solid black;
        @media screen and(max-width: 750px) {
          width: 100%;
          margin-left: 0;
          margin-top: 8px;
        }

        p {
          text-align: justify;
          letter-spacing: 0.3px;
          font-weight: 400;

          // @media screen and(max-width: 750px) {
          //   text-align: start;
          // }
        }
      }

      .info-Teho {
        margin-left: 0;
      }
    }

    .contact {
      width: 100%;
      margin-top: 8px;
      padding: 8px;
      h2 {
        margin-bottom: 16px;
      }

      form {
        display: flex;
        flex-direction: column;

        .contact-groupe-info {
          display: flex;
          justify-content: space-between;
          input {
            padding-left: 16px;
            width: 32.5%;
            height: 30px;
            border: 1px solid black;
            letter-spacing: 0.3px;
            font-family: $font;
            font-size: 1em;
          }
        }
        textarea {
          font-size: 1em;
          letter-spacing: 0.3px;
          padding-left: 16px;
          font-weight: 600;
          margin-top: 16px;
          border: 1px solid black;
          width: 100%;
          height: 90px;
          font-family: $font;
        }

        input[type="submit"] {
          height: 30px;
          border: 1px solid black;
          margin-top: 16px;
          background-color: black;
          color: white;
          letter-spacing: 1.5px;
          cursor: pointer;
          font-weight: 600;
          &:hover {
            background-color: white;
            color: black;
            font-size: 1.2em;
          }
        }
      }
    }
  }
}
