.wall-music {
  max-width: 1200px;
  margin: auto;
  margin-top: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media screen and(max-width: 630px) {
    margin-top: 38px;
  }
  @media screen and(max-width: 320px) {
    margin-top: 20px;
  }

  .card-container {
    width: 282px;
    margin-bottom: 18px;
    margin-right: 9px;
    margin-left: 9px;
    box-shadow: 0 4px 10px 0 rgba($color: black, $alpha: 0.5);

    @media screen and(max-width: 600px) {
      // margin-right: 18px;
      // margin-left: 18px;
      width: 260px;
    }
    @media screen and(max-width: 320px) {
      width: 250px;
    }

    .cover-click {
      width: 100%;
      img {
        width: 100%;
        padding: 8px;
        transition: all ease-in-out 0.2s;
        &:hover {
          transform: scale(1.06);
          transition: all ease-in-out 0.2s;
        }
      }
    }

    .lecteur-spotify {
      padding: 0 8px;
    }

    .footer-card {
      display: flex;
      justify-content: space-between;

      padding: 0 8px 8px 8px;
      position: relative;

      .info-music {
        h3 {
          white-space: nowrap;
          font-size: 1em;
          letter-spacing: 0px;
        }
        p {
          font-size: 0.9em;
        }
      }

      .boutton-stream {
        bottom: 4px;
        right: 8px;
        position: absolute;
        width: 70px;

        &:hover {
          transform: scale(1.05);
        }
        img {
          width: 100%;
        }
      }
    }
  }
}
