.caroussel {
  width: 100vw;
  height: 100vh;
  background: black;
  position: fixed;
  z-index: -100;
  margin-left: -1px;
  @media screen and(max-width:930px) {
    margin-left: 0px;
  }

  img {
    position: absolute;
    left: 0;
    right: 300px;
    top: 0;
    bottom: 0;
    width: inherit;
    height: inherit;
    object-fit: cover;
  }
}