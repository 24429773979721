.wall-artist {
  max-width: 1200px;
  margin: auto;
  margin-top: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  // @media screen and(max-width: 800px) {
  //   justify-content: space-evenly;
  // }
  @media screen and(max-width: 630px) {
    margin-top: 38px;
  }
  @media screen and(max-width: 320px) {
    margin-top: 20px;
  }

  .card-artist-container {
    width: 382px;
    margin-bottom: 18px;
    margin-right: 9px;
    margin-left: 9px;
    box-shadow: 0 4px 10px 0 rgba($color: black, $alpha: 0.5);

    @media screen and(max-width: 1200px) {
      width: 350px;
    }
    @media screen and(max-width: 1104px) {
      width: 314px;
    }

    @media screen and(max-width: 630px) {
      width: 260px;
    }

    .img-container {
      width: 100%;
      height: fit-content;
      padding: 8px;
      img {
        width: 100%;
      }
      .image-lien {
        margin-top: 8px;
        margin-right: 8px;
        margin-bottom: 8px;
        width: 96%;
        transition: all ease-in-out 0.2s;
        @media screen and(max-width: 1200px) {
          width: 95%;
        }
        @media screen and(max-width: 630px) {
          width: 94%;
        }
        &:hover {
          transform: scale(1.04);
          transition: all ease-in-out 0.2s;
          @media screen and(max-width: 1200px) {
            transform: scale(1.06);
          }
        }
      }
    }

    .artist-main {
      display: flex;
      justify-content: space-between;
      padding: 0 8px 8px 8px;
      align-items: center;

      h3 {
        white-space: nowrap;
        letter-spacing: 0px;
        font-size: 1em;
      }

      a {
        margin: 0 4px;
        font-size: 1.5em;

        .fa-facebook-square:hover {
          color: #3b5998;
          transform: scale(1.3);
        }
        .fa-instagram:hover {
          // background: linear-gradient(0.48turn, #833ab4, #fcaf45);
          background: linear-gradient(
            0.55turn,
            #515bd4,
            #8134af,
            #dd2a7b,
            #feda77,
            #f58529
          );
          background-clip: text;
          -webkit-background-clip: text;
          color: transparent;
          transform: scale(1.3);
        }
        .fa-soundcloud:hover {
          background: linear-gradient(0.48turn, #ff8800, #ff3300);
          background-clip: text;
          -webkit-background-clip: text;
          color: transparent;
          transform: scale(1.3);
        }
      }
    }

    iframe {
      padding: 0 8px;
    }
  }
}
